import React from "react"
import { format } from "date-fns"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

import youtube from "@images/global/youtube.svg"
import twitter from "@images/global/twitter.svg"
import instagram from "@images/global/instagram.svg"
import facebook from "@images/global/facebook.svg"

import SVG from "@components/SVG"

import "./style.scss"

const Footer = ({ globalConfig }) => (
  <div className="footer -mt-8 bg-regalblue relative text-orange2 z-50">
    <div className="lg:container mx-auto pt-8 md:pt-0 px-8 lg:px-20 xl:px-32">
      <div className="flex flex-col md:flex-row">
        <div className="flex-1 flex flex-col md:border-l border-mayablue text-xs">
          <div className="flex-1 flex-col flex justify-center pt-12 pb-8 lg:py-0 px-8 border-b border-mayablue text-center md:text-left">
            <div className="flex flex-col lg:flex-row items-center md:items-start lg:items-center justify-center lg:justify-start ">
              <div className="flex flex-col">
                <ReactMarkdown className="markdown">
                  {globalConfig?.copyright}
                </ReactMarkdown>
              </div>
              {globalConfig?.ratingIcon ? (
                <div className="flex -m-4 mt-0 lg:-mt-4 lg:ml-0">
                  <div className="p-4">
                    {globalConfig?.ratingIcon?.format === "svg" ? (
                      <SVG
                        src={globalConfig?.ratingIcon?.url}
                        className="flex-none w-16 h-auto"
                      />
                    ) : (
                      <GatsbyImage
                        image={getImage(globalConfig?.ratingIcon)}
                        className="flex-none w-16 h-auto"
                      />
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex-1 flex flex-col justify-center px-8 py-8 md:py-12 lg:py-0 text-center md:text-left">
            <ReactMarkdown className="markdown">
              {globalConfig?.privacyPolicy}
            </ReactMarkdown>
            <ReactMarkdown className="markdown">
              {globalConfig?.mailchimp}
            </ReactMarkdown>

            <div className="flex items-center justify-center md:justify-start mt-4">
              <button id="ot-sdk-btn" className="ot-sdk-show-settings">
                {globalConfig?.cookieSettings}
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center justify-center py-12 md:py-20 px-8 relative md:border-l border-mayablue text-center">
          {globalConfig?.logos?.length > 0 ? (
            <div className="flex flex-wrap items-center justify-center -m-3">
              {globalConfig?.logos?.map(({ format, url, ...image }, i) => (
                <div key={i} className="p-3">
                  {format === "svg" ? (
                    <SVG
                      src={url}
                      className={`flex-none ${
                        i > 1 ? "w-12" : "w-24 max-w-[4rem]"
                      } h-auto`}
                    />
                  ) : (
                    <GatsbyImage
                      image={getImage(image)}
                      key={i}
                      className={`flex-none ${
                        i > 1 ? "w-12" : "w-24 max-w-[4rem]"
                      } h-auto`}
                    />
                  )}
                </div>
              ))}
            </div>
          ) : null}

          <span className="block w-3 h-3 absolute -bottom-4 left-4 transform -translate-y-8">
            <span className="block w-1 h-1 absolute top-0 left-0 bg-mayablue" />
            <span className="block w-1 h-1 absolute bottom-0 left-0 bg-mayablue" />
            <span className="block w-1 h-1 absolute bottom-0 right-0 bg-mayablue" />
          </span>
          <span className="block w-3 h-3 absolute -bottom-4 right-4 transform -translate-y-8">
            <span className="block w-1 h-1 absolute top-0 right-0 bg-mayablue" />
            <span className="block w-1 h-1 absolute bottom-0 left-0 bg-mayablue" />
            <span className="block w-1 h-1 absolute bottom-0 right-0 bg-mayablue" />
          </span>
        </div>
        <div className="py-12 md:py-20 px-8 md:border-l border-mayablue text-center">
          <h4 className="font-display font-bold text-xl md:text-2xl lg:text-3xl uppercase italic">
            <span className="block">{globalConfig?.stayUpdated}</span>{" "}
            <span className="block">{globalConfig?.followUs}</span>
          </h4>
          <div className="flex items-center justify-center md:justify-start mt-8">
            <a
              href="https://www.youtube.com/channel/UCLsfJMfdCK0wav_0aRr--0Q"
              target="_blank"
              rel="noopener nofollow noreferrer"
              className="group flex items-center justify-center font-bold relative"
            >
              <span className="block circle-with-tag w-14 h-14 transform hover:rotate-180 origin-center rounded-full border-2 border-orange2 transition-all" />
              <SVG
                src={youtube}
                className="w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
              />
            </a>
            <a
              href="https://twitter.com/ZyngaStarWars"
              target="_blank"
              rel="noopener nofollow noreferrer"
              className="group flex items-center justify-center font-bold ml-4 relative"
            >
              <span className="block circle-with-tag w-14 h-14 transform hover:rotate-180 origin-center rounded-full border-2 border-orange2 transition-all" />
              <SVG
                src={twitter}
                className="w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
              />
            </a>
            <a
              href="https://www.instagram.com/zyngastarwars/"
              target="_blank"
              rel="noopener nofollow noreferrer"
              className="group flex items-center justify-center font-bold ml-4 relative"
            >
              <span className="block circle-with-tag w-14 h-14 transform hover:rotate-180 origin-center rounded-full border-2 border-orange2 transition-all" />
              <SVG
                src={instagram}
                className="w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
              />
            </a>
            <a
              href="https://www.facebook.com/groups/starwarshunters/"
              target="_blank"
              rel="noopener nofollow noreferrer"
              className="group flex items-center justify-center font-bold ml-4 relative"
            >
              <span className="block circle-with-tag w-14 h-14 transform hover:rotate-180 origin-center rounded-full border-2 border-orange2 transition-all" />
              <SVG
                src={facebook}
                className="w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
